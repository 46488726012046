<template>
  <main class="private-page">
    <PageTitle>
      <template #default>
        <slot name="title"></slot>
      </template>
      <template #extra>
        <slot name="title-extra"></slot>
      </template>
    </PageTitle>

    <div class="pt-4" :class="{ 'container-fluid': !noGutters }">
      <div v-if="$scopedSlots['title-extra']" class="d-md-none mb-3 text-right">
        <slot name="title-extra"></slot>
      </div>
      <slot name="default"></slot>
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';

export default {
  components: {
    PageTitle,
  },
  props: {
    noGutters: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

.private-page {
}

</style>
