<template>
  <div class="private-page-title d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center text-white">
    <div class="col-auto py-2 px-3">
      <h1 class="private-page-title__text m-0 font-weight-bold text-uppercase"><slot name="default"></slot></h1>
    </div>
    <div class="d-none d-md-block col private-page-title__extra py-2 text-right"><slot name="extra"></slot></div>
  </div>
</template>

<script>

export default {};

</script>

<style lang="scss">

.private-page-title {
  background: #3F3C3A;

  &__text {
    font-size: 1.5rem;

    @include media-breakpoint-up("md") {
      font-size: 2.5rem;
    }
  }

  .btn {
    white-space: nowrap;
  }
}

</style>
