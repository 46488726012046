<template>
  <Page class="page-admin page-admin-dashboard">
    <template #title>Dashboard</template>

    <template v-if="isMerchant" #title-extra></template>

    <template v-if="isMerchant" #default>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Codice riscatto</h3>

              <p class="h1">{{ shop.redeem_code }}</p>
              <router-link class="btn btn-outline-primary" :to="{ name: 'shop.settings.invoice.edit', params: { edit: 'edit' } }">Modifica il tuo codice</router-link>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <button type="button" class="btn btn-outline-primary" @click.prevent="fetchReport('teamSystem')">
                Vendite mese passato (Formato Team System)
              </button>
              <button type="button" class="mt-3 btn btn-outline-primary" @click.prevent="fetchReport('csv')">
                Vendite mese passato (Formato CSV)
              </button>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <button type="button" class="btn btn-outline-primary" @click.prevent="fetchReport('teamSystem', 'current')">
                Vendite parziali mese corrente (Formato Team System)
              </button>
              <button type="button" class="mt-3 btn btn-outline-primary" @click.prevent="fetchReport('csv', 'current')">
                Vendite parziali mese corrente (Formato CSV)
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Shop</h3>

              <a v-if="shopUrl" class="btn btn-outline-primary w-100" :href="shopUrl" target="_blank">Apri shop</a>
              <p v-else>CONFIGURA</p>
            </div>
            <img v-if="shop.qr" class="card-img-bottom" :src="shop.qr" :alt="'QR Code per ' + shop.url" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Pagamento diretto</h3>

              <a v-if="shop.payUrl" class="btn btn-outline-primary w-100" :href="shop.payUrl" target="_blank">Vai al pagamento diretto</a>
              <p v-else>ABILITA</p>
            </div>
            <img v-if="shop.qr_pay" class="card-img-bottom" :src="shop.qr_pay" :alt="'QR Code per ' + shop.payUrl" />
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import Page from '@/views/components/Private/Page.vue';
import saveStringAsFile from '@/helpers/saveStringAsFile.js';
import { getYmd } from '@/helpers/date.js';

export default {
  mixins: [rolesMixin],
  components: {
    Page,
  },
  data: () => ({}),
  computed: {
    shop () {
      return this.$oauth2?.me?.shop || {};
    },
    shopUrl () {
      return this.shop?.url;
    },
    payShopUrl () {
      return this.shop?.payUrl;
    },
  },
  methods: {
    fetchReport (format = 'teamSystem', when = 'last') {
      const now = new Date();

      let previousMonthFirstDay;
      let previousMonthLastDay;

      if (when === 'current') {
        previousMonthFirstDay = new Date(now.getFullYear(), now.getMonth(), 1);

        previousMonthLastDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        previousMonthLastDay.setDate(previousMonthLastDay.getDate() - 1);
      } else {
        previousMonthFirstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);

        previousMonthLastDay = new Date(now.getFullYear(), now.getMonth(), 1);
        previousMonthLastDay.setDate(previousMonthLastDay.getDate() - 1);
      }

      this.$api.exportPurchases(format || 'teamSystem', {
        since: getYmd(previousMonthFirstDay),
        until: getYmd(previousMonthLastDay),
        shop: this.shop.id,
      })
        .then(res => res.data)
        .then(resBody => {
          saveStringAsFile(`ezicommerce-purchases-shop-${this.shop.id}.${format === 'teamSystem' ? 'teamSystem' : 'csv'}`, resBody);
        })
        .catch(err => this.$log.error(err))
      ;
    },
  },
};

</script>
